@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLarge1XFrom (min-width: $tokenSizingBreakpointLarge1XFrom);

.root {
  --margin: calc(-1 * 10%);

  aspect-ratio: 123 / 52;
  border-radius: $tokenBorderRadiusLarge4X;
  display: flex;
  justify-content: flex-end;
  margin-bottom: $tokenSpacingLarge8X;
  margin-left: var(--margin);
  margin-right: var(--margin);
  overflow: hidden;
  position: relative;

  & .image {
    bottom: 0;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  & .content {
    --margin: $tokenSpacingLarge3X;
    --marginSm: $tokenSpacingDefault;

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--margin);
    margin-left: var(--margin);
    margin-right: var(--margin);

    @media (--breakpointMediumFrom) {
      margin-bottom: var(--marginSm);
    }

    & h2 {
      white-space: pre-wrap;

      @media (--breakpointMediumFrom) {
        margin-bottom: var(--marginSm);
      }

      @media (--breakpointLarge1XFrom) {
        margin-bottom: var(--margin);
      }
    }

    & button {
      @media (--breakpointMediumFrom) {
        margin-top: calc($tokenSpacingLarge1X - $tokenSpacingSmall3X);
      }

      @media (--breakpointLarge1XFrom) {
        margin-top: $tokenSpacingLarge4X;
      }
    }
  }
}
