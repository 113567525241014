@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  --copyFontSize: $tokenFontSizesDefault;
  --copyLineHeight: $tokenLineHeightsSmall2X;
  --marginBottom: calc(
    $tokenSpacingDefault + ($tokenFontSizesDefault * $tokenLineHeightsSmall2X) * 2
  );

  align-items: center;
  color: $tokenColorsGreyscaleDefault;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-bottom: var(--marginBottom);

  @media (--breakpointMediumFrom) {
    flex: unset;
  }

  & .icon {
    --marginX: calc($tokenSpacingSmall3X + $tokenSpacingSmall4X);

    align-items: center;
    background-color: $tokenColorsGreyscaleLight5X;
    border-radius: $tokenBorderRadiusLarge4X;
    display: flex;
    height: $tokenSpacingLarge6X;
    justify-content: center;
    margin-left: var(--marginX);
    margin-right: var(--marginX);
    position: relative;
    width: $tokenSpacingLarge6X;

    & .copy {
      bottom: calc(-1 * var(--marginBottom));
      font-size: var(--copyFontSize);
      line-height: var(--copyLineHeight);
      position: absolute;
      text-align: center;
      white-space: pre-wrap;
      width: $tokenSpacingLarge8X;

      @media (--breakpointMediumFrom) {
        color: $tokenColorsBrandTertiaryDefault;
      }
    }
  }

  & .divider {
    background-color: $tokenColorsGreyscaleLight5X;
    height: calc($tokenSpacingSmall4X / 2);
    width: $tokenSpacingLarge2X;
  }
}
